import React, { useState } from "react";

import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { Observable } from "../../../auto/js/events/Observable";
import { formState } from "../../../auto/js/forms/FormState";
import { geoDataMetadataLoader } from "../metadata/GeoDataMetadataLoader";
import { SimpleAutoCompleteInput } from "../widgets/SimpleAutoCompleteInput";
import { whoami } from "../users/UserInfo";

const countryObservable = new Observable();
const regionNameObservable = new Observable();
const resetRegionnameEvent = new Observable();
const localGovNameObservable = new Observable();
const resetLocalgovnameEvent = new Observable();
const resetRegareanameEvent = new Observable();

let resetList =[resetRegionnameEvent,resetLocalgovnameEvent,resetRegareanameEvent,];
export const addressFields = [
 	{
    	title: "Country", field: "country",
    	 editComponent: props => {
    		let [value, setValue] = useState({key: 5614, value: "Guinea�Bissau"});
    		const handleCountryChange = (value) => {
		        resetFieldsFromIndex(0);
		        if (value.key) {
		            countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
			return (
	        	<SimpleAutoCompleteInput  name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} handleChange={(data) => handleCountryChange(data)}   defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.country)?rowData.country.value:""}</>
    },
 	{
    	title: "Region", field: "regionName",
    	 editComponent: props => {
    		let [options, setOptions] = useState(geoDataMetadataLoader.getChilds(1));
    		let [value, setValue] = useState(props.value);
    		const handleRegionnameChange = (value) => {
		        resetFieldsFromIndex(1);
		        if (value.key) {
		            regionNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.country && props.rowData.country != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChildsByAreaId(props.rowData.country.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"regionName"} options={() => options} handleChange={(data) => handleRegionnameChange(data)} observable={countryObservable} reset={resetRegionnameEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.regionName)?rowData.regionName.value:""}</>
    },
 	{
    	title: "local Government", field: "localGovName",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleLocalgovnameChange = (value) => {
		        resetFieldsFromIndex(2);
		        if (value.key) {
		            localGovNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.regionName && props.rowData.regionName != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChildsByAreaId(props.rowData.regionName.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"localGovName"} options={() => options} handleChange={(data) => handleLocalgovnameChange(data)} observable={regionNameObservable} reset={resetLocalgovnameEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.localGovName)?rowData.localGovName.value:""}</>
    },
 	{
    	title: "Registration Area", field: "regAreaName",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleRegareanameChange = (value) => {
		        if (value.key) {
                    props.onChange(value);
		            setValue(value);
		        }
   			}
   			if (props.rowData.stateName && props.rowData.stateName != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChildsByAreaId(props.rowData.stateName.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"regAreaName"} options={() => options} handleChange={(data) => handleRegareanameChange(data)} observable={localGovNameObservable} reset={resetRegareanameEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.regAreaName)?rowData.regAreaName.value:""}</>
    },
    {title: "fromDate", field: "fromDate", type:"date"},
    {title: "toDate", field: "toDate", type:"date"},
]

/* export const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {civilStatusMtlbId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getAddressData(filter).then(response => {
        data = filterData(response);
        data.forEach(element => {
            loadGeoData(element);
        });
        formState.setAddressList(data)
        return countAddressData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
} */

export const buildAddressData = async (query, id) => {
    let data;
    return await getAddressData().then(response => {
        data = response;
        data.forEach(element => {
            loadGeoData(element);
        });
        formState.setAddressList(data)
        return {data: data, totalCount: data.length, page: query.page}
    });
}

/* export const getAddressData = async (filter) => {
    return rest.search('address', filter)
} */

export const getAddressData = async () => {
    return rest.request(getServiceUri() + "/address-service/fetch-addresses/" + whoami().vitalRecordId, "GET")
}

export const loadGeoData = async (element) => {
    if (element.areaId != null) {
        let geographicData = getGeographicDataFromLocation(element.areaId);
        element.country = geographicData.country;
        element.regionName = geographicData.regionName;
        element.stateName = geographicData.stateName;
        element.localGovName = geographicData.localGovName;
        element.regAreaName = geographicData.regAreaName;
        element.pollingUnitName = geographicData.pollingUnitName;
    }
}

export const countAddressData = async (data) => {
    return data.length;
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let fromDate = row.fromDate
        if ( fromDate !== null) {
            let date = new Date(fromDate[0]+ "/" + fromDate[1] + "/" + fromDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.fromDate = date;
        }
        let toDate = row.toDate
        if ( toDate !== null) {
            let date = new Date(toDate[0]+ "/" +toDate[1] + "/" + toDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.toDate = date;
        }
        newRows.push(row);
    }
    return newRows;
}

export const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {

            let dto = pojoMetadata['address'].form2dto(newData);
            dto["vitalRecordId"] = id;
            form2Dto(dto, newData);
            try {
                return rest.request(getServiceUri() + "/address-service/add-address", "POST", dto);
            } catch (err) {
                alert(err);
            }
        }),
        editables.onRowUpdate = (newData, oldData) =>
            new Promise((resolve, reject) => {
                let dto = pojoMetadata['address'].form2dto(newData);
                newData.id = oldData.id;
            	form2Dto(dto, newData);
                try {
                    return rest.request(getServiceUri() + "/address-service/update-address", "PUT", dto).then(() => {
                        formState.addToAddressList(newData)
                        resolve()}).catch((e) => console.table(e))

                } catch (err) {
                    alert(err);
                }
            }),
        editables.onRowDelete = oldData =>
            new Promise((resolve, reject) => {
                try {
                    return rest.request(getServiceUri() + "/address-service/delete-address/" + id, "DELETE", dto).then(() => resolve());
                } catch (err) {
                    alert(err);
                }
            })
    return editables;
}

let getGeographicDataFromLocation = (areaId) => {
    let geoComponents = areaId.split(".");
    let geoResult = {};
    geoResult.country = (geoComponents.length >= 1) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")].id ,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")].name:"" } : {};
    geoResult.regionName = (geoComponents.length >= 2) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")].id,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")].name:"" } : {};
    geoResult.stateName = (geoComponents.length >= 3) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")].id,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")].name:"" } : {};
    geoResult.localGovName = (geoComponents.length >= 4) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")].id,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")].name:"" } : {};
    geoResult.regAreaName = (geoComponents.length >= 5) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")].id,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")].name:"" } : {};
    geoResult.pollingUnitName = (geoComponents.length >= 6) ? { key: geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")].id,
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")].name:"" } : {};
    return geoResult;
}

export const form2Dto = (dto, form) => {
		if (form.pollingUnitName?.key)
			dto["areaId"] = geoDataMetadataLoader.getAreaId(form.pollingUnitName.key);
		else if(form.regAreaName?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.regAreaName.key);
		else if(form.localGovName?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.localGovName.key);
		else if(form.stateName?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.stateName.key);
		else if(form.regionName?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.regionName.key);
        else if(form.country?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.country.key);
}
const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++) {
        resetList[i].publish()
    }
}